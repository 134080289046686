import { DeleteOutlined, EllipsisOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Table, Tooltip } from "antd";
import Dropdown from "antd/es/dropdown/dropdown";
import Compact from "antd/es/space/Compact";
import { useContext, useMemo } from "react";
import {
  BarcodeModal,
  EditNomenclatureModal,
  GroupNomenclatureModal,
} from "src/features/Modal/";
import { EditableCell, EditableRow } from "src/shared";
import { NomenclatureContext } from "src/shared/lib/hooks/context/getNomenclatureContext";
import { setColumnCellProps } from "../lib/setCollumnCellProps";
import { COL_NOMENCLATURE } from "../model/constants";

export default function Nomenclature({
  handleRemove,
  total,
  handleSave,
  handleSaveImage,
  handleDeleteImage,
  queryOffsetData,
  dataSource,
  search,
  page,
  pageSize,
}) {
  const { token } = useContext(NomenclatureContext);

  const columns = useMemo(
    () =>
      setColumnCellProps(COL_NOMENCLATURE, {
        type: [
          {
            key: "render",
            action: (record) => {
              if (!record) {
                return "Не указано";
              }

              if (record === "product") {
                return "Товар";
              }

              if (record === "service") {
                return "Услуга";
              }

              return record;
            },
          },
        ],
        name: [
          {
            key: "render",
            action: (record) => (
              <Tooltip placement="topLeft" title={record}>
                {record}
              </Tooltip>
            ),
          },
        ],
        description_short: [
          {
            key: "render",
            action: (record) => (
              <Tooltip placement="topLeft" title={record}>
                {record}
              </Tooltip>
            ),
          },
        ],
        description_long: [
          {
            key: "render",
            action: (record) => (
              <Tooltip placement="topLeft" title={record}>
                {record}
              </Tooltip>
            ),
          },
        ],
        action: [
          {
            key: "render",
            style: { padding: "0" },
            action: (_, record) => {
              const items = [
                {
                  key: "1",
                  label: (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <BarcodeModal record={record} token={token} />
                    </div>
                  ),
                },
                {
                  key: "2",
                  label: (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <GroupNomenclatureModal record={record} token={token} />
                    </div>
                  ),
                },
                // {
                //   key: "3",
                //   label: (
                //     <div style={{ display: "flex", alignItems: "center" }}>
                //       <AttributesNomenclature record={record} token={token} />
                //     </div>
                //   ),
                // },
              ];

              return (
                <Compact
                  style={{
                    padding: "0",
                  }}
                >
                  <EditNomenclatureModal
                    record={record}
                    handleSave={handleSave}
                    handleSaveImage={handleSaveImage}
                    handleDeleteImage={handleDeleteImage}
                  />
                  <Tooltip title={"Удалить номенклатуру"}>
                    <Popconfirm
                      title={"Подтвердите удаление"}
                      onConfirm={() => handleRemove(record.id, dataSource)}
                    >
                      <Button icon={<DeleteOutlined />} />
                    </Popconfirm>
                  </Tooltip>
                  <Dropdown
                    menu={{ items }}
                    trigger={["click"]}
                    placement="bottomCenter"
                  >
                    <Button icon={<EllipsisOutlined />} />
                  </Dropdown>
                </Compact>
              );
            },
          },
          {
            key: "onCell",
            action: () => ({ style: { padding: 0 } }), // Убираем padding у ячеек колонки "Действие"
          },
        ],
      }),
    [
      dataSource,
      handleDeleteImage,
      handleRemove,
      handleSave,
      handleSaveImage,
      token,
    ]
  );

  return (
    <>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={dataSource}
        components={{
          body: {
            cell: EditableCell,
            row: EditableRow,
          },
        }}
        pagination={{
          current: page,
          pageSize: pageSize,
          total: total,
          showSizeChanger: true,
          onShowSizeChange: async (_, size) =>
            await queryOffsetData(page, size, search),
          onChange: async (page, pageSize) =>
            await queryOffsetData(page, pageSize, search),
        }}
        bordered
        rowClassName={() => "editable-row"}
        style={{ width: "100%" }}
      />
    </>
  );
}
