import React, { useState, useEffect } from "react";
import { Button, Modal, Row, Col, Space, Table, message } from "antd";
import { apiRequests } from "src/shared/api/api";
import dayjs from "dayjs";
import { DebounceSelect } from "src/shared";
import { useSearchParams } from "react-router-dom";

function YookassaButtonModal({ query, websocket }) {
  const [state, setState] = useState({
    warehouse_id: null,
    idIntegration: 1,
    installList: [],
    buttonLoad: true,
    blockButton: false,
    accountsLoad: false,
    isModalVisible: false,
  });

  // eslint-disable-next-line
  const [data, setData] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [messageApi, contextHolder] = message.useMessage();
  // const [accounts, setAccounts] = useState([]);

  const check = async () => {};

  const fetchWarehouse = async (name) => {
    if (name) {
      return fetch(`https://${process.env.REACT_APP_APP_URL}/api/v1/warehouses/?name=${name}&token=${query?.token}`)
        .then((response) => response.json())
        .then((body) => {
          return body;
        })
        .then((body) => {
          // if (body.result) {
          return body.result.map((payment) => ({
            label: payment.name,
            value: payment.id,
          }));

          // }
        })
        .then((body) => {
          return body;
        });
    } else {
      return fetch(`https://${process.env.REACT_APP_APP_URL}/api/v1/warehouses/?token=${query?.token}`)
        .then((response) => response.json())
        .then((body) => {
          return body;
        })
        .then((body) => {
          // if (body.result) {
          return body.result.map((payment) => ({
            label: payment.name,
            value: payment.id,
          }));

          // }
        })
        .then((body) => {
          return body;
        });
    }
  };

  const getInstallList = async () => {
    await apiRequests.integrationYookassa
      .getIntegrationInstallList()
      .then((data) => setState({ ...state, installList: data.data }));
  };

  const setInstall = async (warehouse) => {
    setState((prev) => ({
      ...prev,
      buttonLoad: true,
      blockButton: true,
    }));
    await apiRequests.integrationYookassa
      .getIntegrationInstall({
        token: query?.token,
        warehouse,
      })
      .then((res) => {
        setState((prev) => ({
          ...prev,
          buttonLoad: false,
          blockButton: false,
        }));
        return res;
      })
      .then((res) => {
        window.location.replace(res?.data);
      })
      .catch(() => {
        setState((prev) => ({
          ...prev,
          buttonLoad: false,
          blockButton: false,
        }));
      });
  };

  const revokeInstall = async (warehouse) => {
    await apiRequests.integrationYookassa
      .getRevokeInstall({ token: query?.token, warehouse })
      .then(() => getInstallList());
  };

  const showModal = () => {
    setState({ ...state, isModalVisible: true });
  };

  const handleOk = () => {
    setState({ ...state, isModalVisible: false });
  };

  const handleCancel = () => {
    setState({ ...state, isModalVisible: false });
  };

  useEffect(() => {
    if (state.isModalVisible === true) {
      getInstallList();
    }
    // eslint-disable-next-line
  }, [state.isModalVisible]);

  useEffect(() => {
    if (query?.yookassa) {
      showModal();
      if (searchParams.has("yookassa")) {
        searchParams.delete("yookassa");
        setSearchParams(searchParams);
      }
    }
    // eslint-disable-next-line
  }, [query.yookassa]);

  return (
    <>
      {contextHolder}
      <Button
        onClick={() => {
          check();
          showModal();
        }}>
        {!data?.integration_status ? "Установить виджет" : "Настройки"}
      </Button>
      <Modal
        closable={false}
        maskClosable={false}
        destroyOnClose={true}
        width={1000}
        title={"Настройка: интеграция tablecrm.com и Юkassa"}
        open={state.isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ danger: true, disabled: state.blockButton }}
        okButtonProps={{ disabled: state.blockButton }}
        cancelText="Отключить виджет"
        okText="Закрыть">
        {state?.isAuth ? (
          <>Авторизаия выполнена успешно</>
        ) : (
          <Space size={"middle"} direction="vertical" style={{ width: "100%" }}>
            <Row>
              <Col>
                Необходимо пройти аутентификацию. Вас перенаправит на странницу авторизации yokassa.ru. Выберите
                разрешения и магазин
              </Col>
            </Row>
            <Row>
              <Space size={"small"} direction="horizontal">
                <Col>
                  <DebounceSelect
                    // mode="multiple"
                    style={{ width: 150 }}
                    placeholder="Введите имя склада"
                    fetchOptions={fetchWarehouse}
                    removeIcon={null}
                    onSelect={(user) => {
                      setState((prev) => ({ ...prev, warehouse_id: user }));
                    }}
                  />
                </Col>
                <Col>
                  <Button
                    loading={!state.buttonLoad}
                    onClick={async () => {
                      if (state.warehouse_id !== null) {
                        if (!state.installList.find((item) => item.warehouse_id === state.warehouse_id)) {
                          setInstall(state.warehouse_id);
                        } else {
                          messageApi.open({
                            type: "warning",
                            content: "С этим магазином интеграция юkassa уже создана",
                          });
                        }
                      } else {
                        messageApi.open({
                          type: "warning",
                          content: "Не выбран магазин для добавления в интеграцию",
                        });
                      }
                    }}>
                    Добавить магазин
                  </Button>
                </Col>
              </Space>
            </Row>
            <Row>
              <Col style={{ width: "100%" }}>
                <Table
                  fullWidth
                  dataSource={state.installList}
                  columns={[
                    { title: "название склада", dataIndex: "warehouse_name", key: "warehouse_name" },
                    { title: "описание", dataIndex: "warehouse_description", key: "warehouse_description" },
                    {
                      title: "последние обновление",
                      dataIndex: "last_update",
                      key: "last_update",
                      render: (value, record) => dayjs(value).format("DD.MM.YYYY HH:mm"),
                    },
                    {
                      title: "Аутентификация с юkassa",
                      dataIndex: "status",
                      key: "status",
                      render: (value, record) =>
                        value ? (
                          <div style={{ color: "green", fontWeight: 500 }}>Выполнена</div>
                        ) : (
                          <div style={{ color: "red", fontWeight: 500 }}>Не выполнена</div>
                        ),
                    },
                    {
                      title: "Действие",
                      key: "action",
                      // width: 100,
                      width: 160,
                      render: (_, record) => {
                        return record.status ? (
                          <>
                            <Button
                              color="danger"
                              variant="solid"
                              onClick={() => {
                                revokeInstall(record.warehouse_id);
                              }}>
                              отозвать доступ
                            </Button>
                          </>
                        ) : (
                          <Button
                            color="primary"
                            variant="solid"
                            onClick={() => {
                              setInstall(record.warehouse_id);
                            }}>
                            выполнить
                          </Button>
                        );
                      },
                    },
                  ]}
                />
              </Col>
            </Row>
          </Space>
        )}
      </Modal>
    </>
  );
}

export default YookassaButtonModal;
