import React, { useState, useEffect } from "react";
import { Form, Button } from "antd";
import { ModalForm } from "src/enitities/Modal";
import { CategoryForm } from "src/enitities/Form";
import { EditOutlined } from "@ant-design/icons";
import { apiRequests } from "src/shared/api/api";

export default function EditCategory({ record, handleSave, token }) {
  const [isOpen, setOpen] = useState(false);
  const [changeData, setChangeData] = useState(false);
  const [parent, setParent] = useState(false);
  const [form] = Form.useForm();
  const handleSubmit = async (data) => {
    data[0].id = record.id;
    try {
      await handleSave(data[0]);
      return true;
    } catch (err) {
      return Promise.reject(err.response?.data.detail[0].msg);
    }
  };

  useEffect(() => {
    if (isOpen) {
      apiRequests.categories.getItems({ limit: 500 })
        .then((res) => {
          const parent = [];
          for (let item of res.data.result) {
            parent.push({ value: item.id, label: item.name });
          }
          setParent(parent);
        });

    }
  }, [isOpen]);

  return (
    <>
      <Button
        icon={<EditOutlined />}
        onClick={() => setOpen(true)}
        style={{ marginRight: "5px" }}
      />
      <ModalForm
        isOpen={isOpen}
        setOpen={setOpen}
        title={"Редактировать категорию"}
        formContext={form}
        handleSubmit={handleSubmit}
        changeData={changeData}
        setChangeData={setChangeData}
      >
        <CategoryForm
          formContext={form}
          parent={parent}
          record={record}
          setChangeData={setChangeData}
          token={token}
        />
      </ModalForm>
    </>
  );
}
