import { DatePicker, Form, InputNumber, Select, Space } from "antd";
import { useContext, useEffect, useState } from "react";
import { LoyalitySettingContext } from "src/shared";

// eslint-disable-next-line no-template-curly-in-string
const templateRule = "Поле обязательно к заполнению";

const timeLifeOptions = [
  {
    value: "Секунды",
    label: "Секунды",
  },
  {
    value: "Минуты",
    label: "Минуты",
  },
  {
    value: "Часы",
    label: "Часы",
  },
  {
    value: "Дни",
    label: "Дни",
  },
  {
    value: "Недели",
    label: "Недели",
  },
  {
    value: "Годы",
    label: "Годы",
  },
];

export default function LoyalitySetting({
  formContext,
  record,
  handleType,
  setChangeData,
  isOpen,
}) {
  const formItemLayout = { labelCol: { span: 12 }, wrapperCol: { span: 11 } };
  const { organizations } = useContext(LoyalitySettingContext);
  const [organization, setOrganizations] = useState([]);
  const [lifetime, setLifetime] = useState(record?.lifetime);
  const [isChangeLifetime, setChangeLifetime] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setLifetime(record?.lifetime); // Обновляем lifetime при открытии модалки
      setChangeLifetime(false); // Сбрасываем флаг изменений
    }
  }, [isOpen, record]);

  const onSelectTypeLifetime = (val) => {
    if (isChangeLifetime === true) {
      switch (val) {
        case "Секунды":
          setLifetime(lifetime);
          break;
        case "Минуты":
          setLifetime(lifetime);
          break;
        case "Часы":
          setLifetime(lifetime);
          break;
        case "Дни":
          setLifetime(lifetime);
          break;
        case "Недели":
          setLifetime(lifetime);
          break;
        case "Годы":
          setLifetime(lifetime);
          break;
        default:
          break;
      }
    } else if (isChangeLifetime === false) {
      switch (val) {
        case "Секунды":
          setLifetime(record.lifetime);
          formContext.setFieldValue("lifetime", record.lifetime);
          break;
        case "Минуты":
          setLifetime(record.lifetime / 60);
          formContext.setFieldValue("lifetime", record.lifetime / 60);
          break;
        case "Часы":
          setLifetime(record.lifetime / 3600);
          formContext.setFieldValue("lifetime", record.lifetime / 3600);
          break;
        case "Дни":
          setLifetime(record.lifetime / 86400);
          formContext.setFieldValue("lifetime", record.lifetime / 86400);
          break;
        case "Недели":
          setLifetime(record.lifetime / (86400 * 7));
          formContext.setFieldValue("lifetime", record.lifetime / (86400 * 7));
          break;
        case "Годы":
          setLifetime(record.lifetime / (86400 * 365));
          formContext.setFieldValue(
            "lifetime",
            record.lifetime / (86400 * 365)
          );
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (organizations && organizations.length !== 0) {
      const organizationsSelect = [];
      for (let item of organizations) {
        const valueObj = { value: item.value, label: item.text };
        organizationsSelect.push(valueObj);
      }
      setOrganizations(organizationsSelect);
    }
  }, [organizations, record, formContext]);

  const onValuesChange = (changedValues, allValues) => {
    setChangeData(true);
  };

  return (
    <Form
      {...formItemLayout}
      form={formContext}
      initialValues={record}
      layout={"horizontal"}
      style={{ maxWidth: "100%" }}
      onValuesChange={onValuesChange}
    >
      <Form.Item
        label={"Организация"}
        name={"organization"}
        initialValue={record?.organization}
        rules={[{ required: true, message: templateRule }]}
      >
        <Select options={organization} />
      </Form.Item>
      <Form.Item
        label={"Процент кэшбека"}
        name={"cashback_percent"}
        rules={[{ required: true, message: templateRule }]}
      >
        <InputNumber style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        label={"Минимальная сумма чека"}
        name={"minimal_checque_amount"}
        rules={[{ required: true, message: templateRule }]}
      >
        <InputNumber style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        label={"Максимальный процент начисления"}
        name={"max_percentage"}
        rules={[{ required: true, message: templateRule }]}
      >
        <InputNumber style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        label={"Максимальный процент списания"}
        name={"max_withdraw_percentage"}
        rules={[{ required: true, message: templateRule }]}
      >
        <InputNumber style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item label="Срок жизни" name="lifetime">
        <Space style={{ width: "100%" }}>
          <InputNumber
            value={lifetime}
            onChange={(val) => {
              formContext.setFieldValue("lifetime", val);
              setLifetime(val);
              setChangeLifetime(true);
            }}
          />
          <Select
            onChange={(val) => {
              if (typeof handleType === "function") {
                handleType(val);
              }
            }}
            style={{ width: "150px" }}
            options={timeLifeOptions}
            defaultValue={"Секунды"}
            onSelect={onSelectTypeLifetime}
          />
        </Space>
      </Form.Item>
      <Form.Item
        label={"Действует от"}
        name={"start_period"}
        rules={[{ required: true, message: templateRule }]}
      >
        <DatePicker format={{ format: "DD.MM.YYYY", type: "mask" }} />
      </Form.Item>
      <Form.Item
        label={"Действует до"}
        name={"end_period"}
        rules={[{ required: true, message: templateRule }]}
      >
        <DatePicker format={{ format: "DD.MM.YYYY", type: "mask" }} />
      </Form.Item>
    </Form>
  );
}
