import { TagsOutlined } from "@ant-design/icons";
import { Button, Modal, Tooltip } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";

const modalHeaderStyle = {
  borderBottom: "1px solid #f0f0f0", // Полоска-разделитель
  paddingBottom: "12px",
};

const AttributesNomenclature = ({ record, token }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      axios
        .get(
          `https://${process.env.REACT_APP_APP_URL}/api/v1/nomenclature/${record.id}/attributes?token=${token}`
        )
        .then((res) => console.log(res.data));
    }
  }, [isOpen, token, record.id]);
  return (
    <>
      <Tooltip title="Атрибуты номенклатуры">
        <Button icon={<TagsOutlined />} onClick={() => setIsOpen(true)} />
      </Tooltip>
      <Modal
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
        title={
          <div style={modalHeaderStyle}>Управление атрибутами номенклатуры</div>
        }
      ></Modal>
    </>
  );
};

export default AttributesNomenclature;
