import { EditOutlined } from "@ant-design/icons";
import { Button, Form } from "antd";
import { lazy, Suspense, useState } from "react";
import { serializeTimestamp } from "src/enitities/Form";
import { ModalForm } from "src/enitities/Modal";
const LoyalitySettingForm = lazy(() =>
  import("src/enitities/Form").then((modal) => ({
    default: modal.LoyalitySettingForm,
  }))
);

export default function EditLoyalitySetting({ record, handleSave }) {
  const [lifetimeType, setLifetimeType] = useState("Секунды");
  const [isOpen, setOpen] = useState(false);
  const [changeData, setChangeData] = useState(false);
  const [form] = Form.useForm();

  const handleType = (val) => {
    setLifetimeType(val);
  };

  const handleSubmit = async (data) => {
    const lifetime = Number(data[0].lifetime);
    const newData = serializeTimestamp(data[0], ["end_period", "start_period"]);
    switch (lifetimeType) {
      case "Секунды":
        newData.lifetime = lifetime;
        break;
      case "Минуты":
        newData.lifetime = lifetime * 60;
        break;
      case "Часы":
        newData.lifetime = lifetime * 3600;
        break;
      case "Дни":
        newData.lifetime = lifetime * 86400;
        break;
      case "Недели":
        newData.lifetime = lifetime * (86400 * 7);
        break;
      case "Годы":
        newData.lifetime = lifetime * (86400 * 365);
        break;
      default:
        break;
    }
    newData.id = record.id;
    try {
      await handleSave(newData);
      return true;
    } catch (err) {
      return Promise.reject(err.message.detail);
    } finally {
      setOpen(false);
    }
  };

  return (
    <>
      <Button
        icon={<EditOutlined />}
        onClick={() => setOpen(true)}
        style={{ marginRight: "5px" }}
      />
      <ModalForm
        width={600}
        isOpen={isOpen}
        setOpen={setOpen}
        formContext={form}
        handleSubmit={handleSubmit}
        setChangeData={setChangeData}
        changeData={changeData}
      >
        {isOpen ? (
          <Suspense fallback={<div></div>}>
            <LoyalitySettingForm
              formContext={form}
              record={record}
              setChangeData={setChangeData}
              handleType={handleType}
              isOpen={isOpen}
            />
          </Suspense>
        ) : null}
      </ModalForm>
    </>
  );
}
