import { DeleteOutlined, GroupOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Input,
  Layout,
  List,
  Modal,
  Popconfirm,
  Table,
  Tooltip,
  Typography,
  message,
} from "antd";
import { Content, Header } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import Compact from "antd/es/space/Compact";
import axios from "axios";
import { useEffect, useState } from "react";
import { CreateNomenclatureGroupDrawer } from "src/enitities/NomenclatureGroups";
import { NomenclatureAutoComplete } from "src/shared";
import NomenclatureSearchField from "src/shared/api/NomenclatureSearchField";
const { Text, Title } = Typography;

const siderStyle = {
  backgroundColor: "white",
  paddingRight: "16px",
  borderRight: "1px solid #f0f0f0",
};

const headerStyle = {
  color: "#fff",
  height: 75,
  backgroundColor: "white",
  width: "100%",
  padding: "0",
  paddingLeft: "16px",
};

const listItemStyle = (isSelected) => ({
  cursor: "pointer",
  background: isSelected ? "#e6f7ff" : "white",
  transition: "background 0.2s ease",
  padding: "10px 16px",
  borderRadius: "6px",
  marginBottom: "8px",
  display: "flex",
  alignItems: "center",
  border: isSelected ? "1px solid #1890ff" : "1px solid #f0f0f0",
});

const modalHeaderStyle = {
  borderBottom: "1px solid #f0f0f0", // Полоска-разделитель
  paddingBottom: "12px",
};

const GroupNomenclatureModal = ({ record, token }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [changeData, setChangeData] = useState(false);
  const [groupsNomenclature, setGroupsNomenclature] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(record.group_id);
  const [nomenclatureList, setNomenclatureList] = useState([]);
  const [mainNomenclature, setMainNomenclature] = useState(null);
  const [newGroupName, setNewGroupName] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [editingGroupId, setEditingGroupId] = useState(null);
  const [editingGroupName, setEditingGroupName] = useState("");

  const openModal = () => {
    setIsOpen(true);
    fetchGroupNomenclature();
  };

  const resetForm = () => {
    setIsOpen(false);
    setChangeData(false);
    setSelectedGroup(null);
  };

  const handleCancel = () => {
    changeData
      ? Modal.confirm({
          title: "Подтвердите действие",
          content: "Вы действительно хотите закрыть окно?",
          okText: "Да",
          cancelText: "Нет",
          onOk: resetForm,
        })
      : resetForm();
  };

  const fetchGroupNomenclature = async () => {
    try {
      const res = await axios.get(
        `https://${process.env.REACT_APP_APP_URL}/api/v1/nomenclature/group?token=${token}`
      );
      setGroupsNomenclature(res.data);
    } catch (error) {
      console.error("Ошибка загрузки групп", error);
    }
  };

  useEffect(() => {
    const fetchGroupById = async () => {
      try {
        const res = await axios.get(
          `https://${process.env.REACT_APP_APP_URL}/api/v1/nomenclature/group/${selectedGroup}?token=${token}`
        );
        setNomenclatureList(res.data.nomenclatures);
      } catch (error) {
        console.error("Ошибка загрузки группы", error);
      }
    };

    if (isOpen && selectedGroup) fetchGroupById();
  }, [selectedGroup, token, isOpen]);

  const onSelectNomenclature = (nomenclature) => {
    axios
      .get(
        `https://${process.env.REACT_APP_APP_URL}/api/v1/nomenclature/${nomenclature}?token=${token}`
      )
      .then((res) => {
        axios
          .post(
            `https://${process.env.REACT_APP_APP_URL}/api/v1/nomenclature/group/add?token=${token}`,
            {
              nomenclature_id: nomenclature,
              group_id: selectedGroup,
              is_main: mainNomenclature === nomenclature,
            }
          )
          .then(() => {
            setNomenclatureList((prevList) => [...prevList, res.data]);
            console.log("Check");
          })

          .catch((err) => {
            if (err.response.status === 409) {
              message.error("Номенклатура уже добавлена в другую группу!", 3);
            }
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSelectMain = (id) => {
    setMainNomenclature(id);
    axios.patch(
      `https://${process.env.REACT_APP_APP_URL}/api/v1/nomenclature/group/${selectedGroup}/select_main?token=${token}&nomenclature_id=${id}`
    );
  };

  const handleDeleteNomenclature = (id) => {
    console.log(id);
    axios
      .delete(
        `https://${process.env.REACT_APP_APP_URL}/api/v1/nomenclature/group/del?token=${token}`,
        {
          data: {
            // Передаем тело запроса через data
            nomenclature_id: id,
            group_id: selectedGroup,
          },
        }
      )
      .then(() => {
        setNomenclatureList((prevList) =>
          prevList.filter((item) => item.id !== id)
        );
      });
  };

  const columns = [
    { title: "Название номенклатуры", dataIndex: "name", key: "name" },
    {
      title: "Главная",
      key: "is_main",
      dataIndex: "id",
      render: (id) => (
        <Checkbox
          checked={mainNomenclature === id}
          onChange={() => handleSelectMain(id)}
        />
      ),
    },
    {
      title: "",
      key: "action",
      dataIndex: "action",
      width: 30,
      render: (_, record) => (
        <Tooltip title="Удалить">
          <Popconfirm
            title="Подтвердите удаление"
            onConfirm={() => handleDeleteNomenclature(record.id)}
            cancelText="Отмена"
            okText="OK"
          >
            <Button icon={<DeleteOutlined />} style={{ marginRight: 10 }} />
          </Popconfirm>
        </Tooltip>
      ),
    },
  ];

  useEffect(() => {
    if (nomenclatureList.length > 0) {
      const mainItem = nomenclatureList.find((item) => item.is_main);
      setMainNomenclature(mainItem ? mainItem.id : null);
    }
  }, [nomenclatureList]);

  const handleCreateGroup = () => {
    if (!newGroupName.trim()) return;

    axios
      .post(
        `https://${process.env.REACT_APP_APP_URL}/api/v1/nomenclature/group?token=${token}`,
        {
          name: newGroupName,
        }
      )
      .then((res) => {
        setGroupsNomenclature([...groupsNomenclature, res.data]);
        setNewGroupName("");
        setIsDropdownOpen(false);
      })
      .catch((error) => {
        console.error("Ошибка создания группы:", error);
      });
  };

  const handleEditGroup = (id, name) => {
    setEditingGroupId(id);
    setEditingGroupName(name);
  };

  const handleSaveGroup = async (id) => {
    if (!editingGroupName.trim()) return;
    try {
      await axios.patch(
        `https://${process.env.REACT_APP_APP_URL}/api/v1/nomenclature/group?token=${token}&id=${id}`,
        { name: editingGroupName }
      );
      setGroupsNomenclature((prev) =>
        prev.map((group) =>
          group.id === id ? { ...group, name: editingGroupName } : group
        )
      );
      setEditingGroupId(null);
    } catch (error) {
      console.error("Ошибка обновления группы", error);
    }
  };

  const handleDeleteGroup = async (id) => {
    try {
      await axios.delete(
        `https://${process.env.REACT_APP_APP_URL}/api/v1/nomenclature/group?token=${token}&id=${id}`
      );
      setGroupsNomenclature((prev) => prev.filter((group) => group.id !== id));
    } catch (error) {
      console.error("Ошибка удаления группы", error);
    }
  };

  return (
    <>
      <Tooltip title="Группировать номенклатуру">
        <Button icon={<GroupOutlined />} onClick={openModal} />
      </Tooltip>

      <Modal
        open={isOpen}
        onCancel={handleCancel}
        footer={null}
        title={
          <div style={modalHeaderStyle}>Управление группами номенклатур</div>
        }
        width={1800}
        centered
      >
        <Layout style={{ minHeight: "400px" }}>
          {/* Боковая панель с группами */}
          <Sider width={300} style={siderStyle}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Title
                level={5}
                style={{ textAlign: "center", marginBottom: 16, marginTop: 0 }}
              >
                Список групп
              </Title>
              <CreateNomenclatureGroupDrawer
                open={isDropdownOpen}
                setOpen={setIsDropdownOpen}
                handleCreateGroup={handleCreateGroup}
                newGroupName={newGroupName}
                setNewGroupName={setNewGroupName}
              />
            </div>

            <List
              dataSource={groupsNomenclature}
              style={{ maxHeight: "75vh", overflow: "auto" }}
              renderItem={(item) => {
                const isSelected = selectedGroup === item.id;
                return (
                  <List.Item
                    style={listItemStyle(isSelected)}
                    onClick={() => setSelectedGroup(item.id)}
                    onDoubleClick={() => handleEditGroup(item.id, item.name)}
                  >
                    {editingGroupId === item.id ? (
                      <Input
                        value={editingGroupName}
                        onChange={(e) => setEditingGroupName(e.target.value)}
                        onBlur={() => handleSaveGroup(item.id)}
                        onPressEnter={() => handleSaveGroup(item.id)}
                        autoFocus
                      />
                    ) : (
                      <Text strong={isSelected} style={{ flex: 1 }}>
                        {item.name}
                      </Text>
                    )}
                    <Tooltip title="Удалить группу">
                      <Popconfirm
                        title="Вы уверены, что хотите удалить группу?"
                        onConfirm={() => handleDeleteGroup(item.id)}
                        cancelText="Отмена"
                        okText="OK"
                      >
                        <Button
                          icon={<DeleteOutlined />}
                          size="small"
                          style={{ marginLeft: 10 }}
                        />
                      </Popconfirm>
                    </Tooltip>
                  </List.Item>
                );
              }}
            />
          </Sider>
          <Layout>
            <Header style={headerStyle}>
              <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
                Выбор номенклатуры
              </Title>
              <Compact style={{ width: "100%" }}>
                <div
                  style={{
                    width: "10%",
                    marginInlineEnd: 0,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <NomenclatureAutoComplete
                    buttonIntegrated
                    placeholder={"Выберите товар"}
                    api={`https://${process.env.REACT_APP_APP_URL}/api/v1/`}
                    token={token}
                    onSelect={onSelectNomenclature}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    marginInlineEnd: 0,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <NomenclatureSearchField
                    api={`https://${process.env.REACT_APP_APP_URL}/api/v1/`}
                    token={token}
                    select={onSelectNomenclature}
                  />
                </div>
              </Compact>
            </Header>
            {/* Основное содержимое с таблицей */}
            <Content
              style={{
                paddingLeft: "16px",
                width: "100%",
                backgroundColor: "white",
              }}
            >
              <Title level={5} style={{ marginTop: 16 }}>
                Детали группы
              </Title>
              <Table
                columns={columns}
                dataSource={nomenclatureList}
                pagination={false}
                bordered
              />
            </Content>
          </Layout>
        </Layout>
      </Modal>
    </>
  );
};

export default GroupNomenclatureModal;
