import { PlusOutlined, ThunderboltOutlined } from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Select,
  Tooltip,
} from "antd";
import Compact from "antd/es/space/Compact";
import Paragraph from "antd/lib/typography/Paragraph";
import { useCallback, useEffect, useState } from "react";
import { ModalForm } from "src/enitities/Modal";
import debounce from "../lib/utils/debounce";
import { apiRequests } from "./api";
import { axiosInstance } from "./axiosInstance";

const { TextArea } = Input;

const NomenclatureSearchField = (props) => {
  const [options, setOptions] = useState([]);
  const [isEmptyList, setIsEmptyList] = useState(true);
  const [searchValue, setSearchValue] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [changeData, setChangeData] = useState(false);
  const [optionsName, setOptionsName] = useState([]);
  const [units, setUnits] = useState([]);
  const [categories, setCategories] = useState([]);
  const [nomenclature, setNomenclature] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);

  const textStyle = {
    lineHeight: "18px",
    color: "black",
    textAlign: "center",
  };

  const handleSearch = useCallback(async (value) => {
    const nomenclatureData = await apiRequests.nomenclature.getItems({
      name: value,
    });
    const options = nomenclatureData.data.result.map((item) => ({
      key: item.id,
      label: item.name,
      value: item.name,
    }));
    setIsEmptyList(options.length === 0);
    setOptions(options);
    setSearchValue(value);
  }, []);

  const debounceSearch = debounce(handleSearch, 600);

  useEffect(() => {
    debounceSearch("");
    // eslint-disable-next-line
  }, []);

  const createNomenclature = async (value) => {
    console.log(value);
    let body = {};
    if (typeof value === "string") {
      body["name"] = value;
    } else if (typeof value === "object") {
      body = value[0];
    }
    const isExist = props.nomDS.find((i) => i.name === body.name);

    if (isExist) {
      return message.info("Данная номенклатура уже существует!");
    }
    if (value) {
      axiosInstance
        .post("/nomenclature/", [body], {
          params: {
            token: window.token,
          },
        })
        .then((res) => {
          console.log(res);
          const option = {
            key: res.data[0].id,
            label: res.data[0].name,
            value: res.data[0].name,
          };
          handleSelect(res.data[0].name, option);
          console.log(option);
          message.success("Номенклатура успешно добавлена!");
        })
        .catch((e) => {
          message.error("Произошла ошибка");
        })
        .finally(() => {
          if (isModalVisible === true) {
            setIsModalVisible(false);
          }
        });
    } else {
      message.info("Вы не ввели название номенклатуры");
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.shiftKey && event.which === 65) {
        event.preventDefault();
        if (isEmptyList) {
          createNomenclature(searchValue);
        }
      }
      if (event.shiftKey && event.which === 83) {
        event.preventDefault();
        if (isEmptyList) {
          setIsModalVisible(true);
          form.setFieldValue("name", searchValue);
        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmptyList, searchValue, handleSearch]);

  const handleSelect = (value, option) => {
    setSearchValue(value);
    props.select(option.key);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onChangeName = (e) => {
    setOptionsName(
      nomenclature.filter((n) =>
        n.value.toLowerCase().includes(e.toLowerCase())
      )
    );
  };

  const debounceOnChangeName = debounce(onChangeName, 500);

  const fetchData = useCallback(async () => {
    const nomenclature = await apiRequests.nomenclature.getItems();
    const optionsNomenclature = nomenclature.data.result.map((item) => ({
      label: `${item.name}`,
      value: item.id,
    }));
    setNomenclature(optionsNomenclature);

    const units = await apiRequests.units.getItems();
    const optionsUnits = units.data.result.map((item) => ({
      label: `${item.name}`,
      value: item.id,
    }));
    setUnits(optionsUnits);

    const categories = await apiRequests.categories.getItems();
    const optionsCategories = categories.data.result.map((item) => ({
      label: `${item.name}`,
      value: item.id,
    }));
    setCategories(optionsCategories);

    const manufacturers = await apiRequests.manufacturers.getItems();
    const optionsManufacturers = manufacturers.data.result.map((item) => ({
      label: `${item.name}`,
      value: item.id,
    }));
    setManufacturers(optionsManufacturers);
  }, []);

  useEffect(() => {
    if (isModalVisible) {
      fetchData();
    }
  }, [isModalVisible, fetchData]);

  const onValuesChange = (changedValues, allValues) => {
    setChangeData(true);
  };

  return (
    <Compact style={{ width: "100%", borderRadius: 0 }}>
      <AutoComplete
        notFoundContent={
          <div>
            <Paragraph style={textStyle}>
              По вашему запросу ничего не найдено.
            </Paragraph>
            <Paragraph style={textStyle}>
              Нажмите сочетание клавиш SHIFT + A для быстрого создания
              номенклатуры или SHIFT + S для создания номенклатуры
            </Paragraph>
          </div>
        }
        {...props}
        onSearch={handleSearch}
        options={options}
        mode=""
        onSelect={handleSelect}
        allowClear
        style={{ width: "100%" }}
      />
      {isModalVisible ? (
        <ModalForm
          title={"Добавить номенклатуру"}
          isOpen={isModalVisible}
          setOpen={setIsModalVisible}
          formContext={form}
          handleSubmit={createNomenclature}
          changeData={changeData}
          setChangeData={setChangeData}
        >
          <Form
            form={form}
            setChangeData={setChangeData}
            onValuesChange={onValuesChange}
          >
            <Form.Item rules={[{ required: true }]} label={"Имя"} name={"name"}>
              <AutoComplete
                options={optionsName}
                onChange={debounceOnChangeName}
              />
            </Form.Item>
            <Form.Item label={"Тип"} name={"type"}>
              <Radio.Group>
                <Radio.Button value="product"> Товар </Radio.Button>
                <Radio.Button value="service"> Услуга </Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item label={"Краткое описание"} name={"description_short"}>
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item label={"Длинное описание"} name={"description_long"}>
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item label={"Код"} name={"code"}>
              <Input />
            </Form.Item>
            <Form.Item label={"Единица измерения"} name={"unit"}>
              <Select filterOption={filterOption} showSearch options={units} />
            </Form.Item>
            <Form.Item label={"Категория"} name={"category"}>
              <Select
                filterOption={filterOption}
                showSearch
                options={categories}
              />
            </Form.Item>
            <Form.Item label={"Производитель"} name={"manufacturer"}>
              <Select
                filterOption={filterOption}
                showSearch
                options={manufacturers}
              />
            </Form.Item>
            <Form.Item label={"Кешбек"} name={"cashback_percent"}>
              <InputNumber />
            </Form.Item>
          </Form>
        </ModalForm>
      ) : null}
      <Tooltip title={"Нажмите для быстрого создания номенклатуры"}>
        <Button
          onClick={() => createNomenclature(searchValue)}
          disabled={!isEmptyList}
          icon={<ThunderboltOutlined />}
        ></Button>
      </Tooltip>
      <Tooltip title={"Нажмите для создания номенклатуры"}>
        <Button
          onClick={() => {
            setIsModalVisible(true);
            form.setFieldValue("name", searchValue);
          }}
          disabled={!isEmptyList}
          icon={<PlusOutlined />}
        ></Button>
      </Tooltip>
    </Compact>
  );
};

export default NomenclatureSearchField;
