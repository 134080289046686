import { Form } from "antd";
import { useContext, useState } from "react";
import { LoyalitySettingForm, serializeTimestamp } from "src/enitities/Form";
import { ModalForm } from "src/enitities/Modal";
import { API, LoyalitySettingContext } from "src/shared";

export default function AddLoyalitySetting({ isOpen, setOpen }) {
  const [changeData, setChangeData] = useState(false);
  const { token, pathname } = useContext(LoyalitySettingContext);
  const [form] = Form.useForm();
  const [lifetimeType, setLifetimeType] = useState("Минуты");

  const handleType = (val) => {
    setLifetimeType(val);
  };

  const handleSubmit = async (data) => {
    let lifetime = Number(data[0].lifetime);
    console.log(lifetime);

    const queryCreate = API.crud.create(token, pathname);
    const newData = serializeTimestamp(data[0], ["start_period", "end_period"]);
    switch (lifetimeType) {
      case "Секунды":
        newData.lifetime = lifetime;
        break;
      case "Минуты":
        newData.lifetime = lifetime * 60;
        break;
      case "Часы":
        newData.lifetime = lifetime * 3600;
        break;
      case "Дни":
        newData.lifetime = lifetime * 86400;
        break;
      case "Недели":
        newData.lifetime = lifetime * (86400 * 7);
        break;
      case "Годы":
        newData.lifetime = lifetime * (86400 * 365);
        break;
      default:
        break;
    }
    try {
      await queryCreate(newData);
      return true;
    } catch (err) {
      return Promise.reject(err.message.detail);
    }
  };

  return (
    <ModalForm
      title={"Добавить настройки к карте"}
      width={600}
      isOpen={isOpen}
      setOpen={setOpen}
      formContext={form}
      handleSubmit={handleSubmit}
      changeData={changeData}
      setChangeData={setChangeData}
    >
      <LoyalitySettingForm
        formContext={form}
        setChangeData={setChangeData}
        handleType={handleType}
        isOpen={isOpen}
      />
    </ModalForm>
  );
}
