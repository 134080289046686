import { Button, Form, Input, Select, Upload, message } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { apiRequests } from "src/shared/api/api";

export default function Categories({
  formContext,
  record,
  parent,
  setChangeData,
  token,
}) {
  const { TextArea } = Input;
  const formItemLayout = { labelCol: { span: 5 }, wrapperCol: { span: 17 } };

  const onValuesChange = (changedValues, allValues) => {
    setChangeData(true);
  };

  const [pictures, setPictures] = useState([]);

  useEffect(() => {
    if (record?.id) {
      apiRequests.pictures
        .getItems({
          entity: "categories",
          entity_id: record.id,
        })
        .then((response) => {
          setPictures(
            response.data.result.map((item) => ({
              id: item.id,
              name: item.url,
              status: "done",
              url: `https://${process.env.REACT_APP_APP_URL}/api/v1/${item.url}/`,
            }))
          );
        });
    }
  }, [record?.id]);

  const validateMessages = {
    /* eslint-disable no-template-curly-in-string */
    required: "Поле обязательно для ввода!",
  };

  const handleDeleteImage = (id) => {
    axios.delete(
      `https://${process.env.REACT_APP_APP_URL}/api/v1/pictures/${id}/?token=${token}`
    );
  };

  return (
    <Form
      {...formItemLayout}
      form={formContext}
      initialValues={record}
      layout={"horizontal"}
      style={{ maxWidth: 600 }}
      validateMessages={validateMessages}
      onValuesChange={onValuesChange}
    >
      <Form.Item rules={[{ required: true }]} label={"Имя"} name={"name"}>
        <Input />
      </Form.Item>
      <Form.Item label={"Описание"} name={"description"}>
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item label={"Код"} name={"code"}>
        <Input />
      </Form.Item>
      <Form.Item label={"Родитель"} name={"parent"}>
        <Select options={parent} />
      </Form.Item>
      <Form.Item label={"Изображение"}>
        <Upload
          action={`https://${process.env.REACT_APP_APP_URL}/api/v1/pictures/?token=${token}&entity=categories&entity_id=${record.id}`}
          fileList={pictures}
          listType="picture-card"
          onChange={({ fileList: newFileList, file: newFile }) => {
            if (newFile.status === "done") {
              setPictures(newFileList);
              return message.success("Изображение было добавлено");
            }
            setPictures(newFileList);
          }}
          onRemove={(picture) => {
            if (picture.response !== undefined) {
              return handleDeleteImage(picture.response.id);
            }
            handleDeleteImage(picture.id);
          }}
        >
          <Button>Загрузить</Button>
        </Upload>
        {/* <div style={{padding: '10px 0', display: 'flex', gap: 10}}>
                  {
                    pics.map(pic => <PreviewImage items={[pic]} width={60} height={60} key={pic.id} />)
                  }
                </div> */}
      </Form.Item>
    </Form>
  );
}
