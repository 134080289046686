import { PlusOutlined } from "@ant-design/icons";
import { Button, Dropdown, Input, Menu, Tooltip } from "antd";

const CreateNomenclatureGroupDrawer = ({
  open,
  setOpen,
  handleCreateGroup,
  newGroupName,
  setNewGroupName,
}) => {
  const menu = (
    <Menu
      onClick={(e) => e.domEvent.stopPropagation()} // Предотвращает закрытие
    >
      <Menu.Item key="input">
        <Input
          placeholder="Название группы"
          value={newGroupName}
          onChange={(e) => setNewGroupName(e.target.value)}
          onClick={(e) => e.stopPropagation()} // Не даёт закрыться при клике
        />
      </Menu.Item>
      <Menu.Item key="submit">
        <Button
          type="primary"
          block
          onClick={(e) => {
            e.stopPropagation(); // Остановить всплытие
            handleCreateGroup();
          }}
        >
          Создать
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      open={open}
      onOpenChange={setOpen}
    >
      <Tooltip title="Добавить группу">
        <Button icon={<PlusOutlined />} />
      </Tooltip>
    </Dropdown>
  );
};

export default CreateNomenclatureGroupDrawer;
