//таблица на странице "Категории"
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Switch, Table } from "antd";
import { useMemo } from "react";
import EditCategory from "src/features/Modal/ui/EditCategory";
import { EditableCell, EditableRow } from "src/shared";
import { setColumnCellProps } from "../lib/setCollumnCellProps";
import { COL_CATEGORIES } from "../model/constants";

export default function Categories({
  handleRemove,
  handleSave,
  dataSource,
  token,
}) {
  const columns = useMemo(
    () =>
      setColumnCellProps(COL_CATEGORIES, {
        status: [
          {
            key: "render",
            action: (checked, record) => (
              <>
                <Switch
                  checked={checked}
                  onChange={(checked) => {
                    record.status = checked;
                    handleSave(record);
                  }}
                />
              </>
            ),
          },
        ],
        action: [
          {
            key: "render",
            action: (_, record) => (
              <>
                <EditCategory
                  record={record}
                  handleSave={handleSave}
                  token={token}
                />
                <Popconfirm
                  title={"Подтвердите удаление"}
                  onConfirm={() => handleRemove(record.id)}
                >
                  <Button icon={<DeleteOutlined />} />
                </Popconfirm>
              </>
            ),
          },
        ],
      }),
    [handleRemove, handleSave, token]
  );

  return (
    <>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={dataSource}
        components={{
          body: {
            cell: EditableCell,
            row: EditableRow,
          },
        }}
        bordered
        rowClassName={() => "editable-row"}
        style={{ width: "100%" }}
      />
    </>
  );
}
