import { BarcodeOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Tooltip,
} from "antd";
import axios from "axios";
import { useState } from "react";

const BarcodeModal = ({ record, token }) => {
  const [form] = Form.useForm();
  const [isOpen, setOpen] = useState(false);
  const [changeData, setChangeData] = useState(false);
  const [barcodes, setBarcodes] = useState([]);

  const validateMessages = {
    /* eslint-disable no-template-curly-in-string */
    required: "Поле обязательно для ввода!",
  };

  const fetchBarcodes = async () => {
    await axios
      .get(
        `https://${process.env.REACT_APP_APP_URL}/api/v1/nomenclature/${record.id}/barcode?token=${token}`
      )
      .then((res) => {
        setBarcodes(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openModal = () => {
    setOpen(true);
    fetchBarcodes();
  };

  const onValuesChange = (changedValues, allValues) => {
    setChangeData(true);
  };

  const resetForm = () => {
    setOpen(false);
    setChangeData(false);
  };

  const handleCancel = () => {
    changeData === true
      ? Modal.confirm({
          title: "Подтвердите действие",
          content: "Вы дейтвительно хотите закрыть окно?",
          okText: "Да",
          cancelText: "Нет",
          onOk: () => {
            resetForm();
          },
        })
      : resetForm();
  };

  const addBarcode = (values) => {
    const barcode = values.barcode;
    barcode &&
      axios
        .post(
          `https://${process.env.REACT_APP_APP_URL}/api/v1/nomenclature/${record.id}/barcode?token=${token}`,
          { barcode }
        )
        .then(() => {
          fetchBarcodes();
          form.resetFields();
        });

    setChangeData(false);
  };

  const deleteBarcode = (barcode) => {
    axios
      .delete(
        `https://${process.env.REACT_APP_APP_URL}/api/v1/nomenclature/${record.id}/barcode?token=${token}`,
        { data: { barcode: barcode } }
      )
      .then(() => {
        fetchBarcodes();
      });
  };

  return (
    <>
      <Tooltip title="Управление штрих-кодами">
        <Button icon={<BarcodeOutlined />} onClick={openModal} />
      </Tooltip>

      <Modal
        title="Управление штрих-кодами"
        open={isOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={form}
          validateMessages={validateMessages}
          layout="horizontal"
          style={{ width: "100%", display: "flex" }}
          onFinish={(values) => addBarcode(values)}
          onValuesChange={onValuesChange}
        >
          <Form.Item
            style={{ width: "100%", marginRight: 5 }}
            rules={[{ required: true }]}
            name="barcode"
          >
            <Input placeholder="Введите штрих-код" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" onClick={addBarcode}>
              Добавить
            </Button>
          </Form.Item>
        </Form>

        {barcodes.length !== 0 ? (
          <Divider style={{ margin: 0, marginBottom: 8 }} />
        ) : null}
        <>
          {barcodes.map((item, id) => {
            return (
              <Card
                styles={{
                  body: {
                    display: "flex",
                    padding: 5,
                    justifyContent: "space-between",
                    width: "100%",
                  },
                }}
                style={{ marginBottom: 5 }}
                hoverable
                key={id}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    paddingLeft: 5,
                  }}
                >
                  {item}
                </div>
                <Popconfirm
                  title={"Подтвердите удаление"}
                  onConfirm={() => deleteBarcode(item)}
                  okText="Да"
                  cancelText="Нет"
                >
                  <Button icon={<DeleteOutlined />}></Button>
                </Popconfirm>
              </Card>
            );
          })}
        </>
      </Modal>
    </>
  );
};

export default BarcodeModal;
